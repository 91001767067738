import swal from "sweetalert2";

export function handleRegistrationError(error) {
    console.log(error);

    if (error.response && error.response.data) {
        if (error.response.data.errors) {
            const validationMessages = error.response.data.errors;

            let errorList = '<ul>';
            for (let field in validationMessages) {
                if (Object.prototype.hasOwnProperty.call(validationMessages, field)) {
                    validationMessages[field].forEach(msg => {
                        errorList += `<li>${msg}</li>`;
                    });
                }
            }
            errorList += '</ul>';

            swal.fire({
                title: 'Fehler',
                html: errorList,
                icon: 'warning'
            });

        }
        // Handling a single error message
        else if (error.response.data.error) {
            swal.fire({
                title: 'Fehler',
                text: error.response.data.error,
                icon: 'error'
            });
        }
        // Handling a single warning message
        else if (error.response.data.warning) {
            swal.fire({
                title: 'Warnung',
                text: error.response.data.warning,
                icon: 'warning'
            });
        }
    } else {
        console.error(error);
        swal.fire('Fehler', 'Es ist ein allgemeiner Fehler aufgetreten.', 'error');
    }
}
